import React from 'react'

export default function HQImage({color, width}) {
  return (
    <svg fill={color} width={width} viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
      <g>
        <path d="M46.8,32.4l-3.7-3.1c0.2-1.1,0.3-2.3,0.3-3.4s-0.1-2.3-0.3-3.4l3.7-3.1c1.2-1,1.6-2.8,0.8-4.2L46,12.3
          c-0.6-1-1.7-1.6-2.9-1.6c-0.4,0-0.8,0.1-1.1,0.2l-4.5,1.7c-1.8-1.6-3.8-2.7-5.8-3.4l-0.8-4.7C30.6,2.9,29.2,2,27.6,2h-3.2
          c-1.6,0-3,0.9-3.3,2.5l-0.8,4.6c-2.2,0.7-4.1,1.9-5.9,3.4L10,10.8c-0.4-0.1-0.7-0.2-1.1-0.2c-1.2,0-2.3,0.6-2.9,1.6l-1.6,2.8
          c-0.8,1.4-0.5,3.2,0.8,4.2l3.7,3.1c-0.2,1.1-0.3,2.3-0.3,3.4c0,1.2,0.1,2.3,0.3,3.4l-3.7,3.1c-1.2,1-1.6,2.8-0.8,4.2L6,39.4
          c0.6,1,1.7,1.6,2.9,1.6c0.4,0,0.8-0.1,1.1-0.2l4.5-1.7c1.8,1.6,3.8,2.7,5.8,3.4l0.8,4.8c0.3,1.6,1.6,2.7,3.3,2.7h3.2
          c1.6,0,3-1.2,3.3-2.8l0.8-4.8c2.3-0.8,4.3-2,6.1-3.7l4.2,1.7c0.4,0.1,0.8,0.2,1.2,0.2c1.2,0,2.3-0.6,2.9-1.6l1.5-2.6
          C48.4,35.2,48,33.4,46.8,32.4z M26.1,37.1c-6,0-10.9-4.9-10.9-11s4.8-11,10.9-11s10.9,4.9,10.9,11S32.1,37.1,26.1,37.1z"/>
      </g>
      <path d="M29,18h-4.6c-0.7,0-1.3,0.4-1.5,1l-2.8,7.2c-0.2,0.5,0.2,1.1,0.8,1.1h4.7l-1.7,6c-0.2,0.6,0.5,0.9,0.9,0.5
        l7.1-8.3c0.5-0.5,0.1-1.3-0.6-1.3h-3.5l3.1-4.9c0.3-0.5-0.1-1.2-0.7-1.2H29z"/>
    </svg>
  )
}
