import React from 'react'

export default function GustaveImage({color, width}) {
  return (
    <svg viewBox="0 0 512 512" width={width}>
      <g>
        <rect y="423.908" width="512" height="40.051" fill={color}/>
        <path fill={color} d="M320.344,151.492c0,0,39.8,7.964,91.522-43.766c23.873,31.838,39.785,47.748,39.785,47.748v47.764
          c15.989-16.474,25.869-38.908,25.869-63.674c0-50.549-40.982-91.522-91.514-91.522c-50.549,0-91.522,40.973-91.522,91.522
          c0,24.766,9.887,47.2,25.861,63.674V151.492z"/>
        <path fill={color}d="M447.38,257.08c-4.279,2.338-30.046,56.548-46.81,92.554l-4.443-62.72h-20.252l-4.443,62.744
          c-16.764-36.014-42.531-90.24-46.809-92.578c-61.86,22.317-64.621,72.31-64.621,150.808H512
          C512,329.39,509.239,279.397,447.38,257.08z"/>
        <polygon fill={color}points="400.468,274.789 403.027,251.69 368.984,251.69 371.533,274.789 	"/>
        <path fill={color} d="M100.127,317.054c-35.827,0-64.871,29.037-64.871,64.871h129.742
          C164.998,346.091,135.954,317.054,100.127,317.054z"/>
        <rect fill={color} x="86.422" y="291.475" width="27.409" height="18.273"/>
        <path fill={color} d="M37.086,408.419h126.082c5.554,0,10.052-4.506,10.052-10.052c0-5.546-4.498-10.052-10.052-10.052H37.086
          c-5.554,0-10.052,4.506-10.052,10.052C27.034,403.914,31.532,408.419,37.086,408.419z"/>
      </g>
    </svg>
  )
}
