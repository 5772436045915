import React, {useEffect, useState} from 'react'
import {Loading} from '@upsellguru/usg-components-lib'
import Login from './pages/Login/Login'
import {AuthContext} from './contexts/AuthContext'
import {LoadingContext} from './contexts/LoadingContext'
import AppPicker from './pages/AppPicker/AppPicker'
import axios from "axios";
import {restUrls} from "./utils/constants";
import {changeWhitelabelTheme} from "./utils/changeWhitelabelTheme";
import MainLoading from "./components/MainLoading/MainLoading";

function App() {
  const resellerId = usg.data.resellerId // eslint-disable-line
  const ssoTokenAudience = usg.data.ssoTokenAudience ?? null // eslint-disable-line
  const redirectTo = usg.data.redirectTo ?? null // eslint-disable-line
  const [isLoadingReseller, setIsLoadingReseller] = useState(resellerId > 0)
  const [resellerLogo, setResellerLogo] = useState(null)
  const [supportEmail, setSupportEmail] = useState(null)
  const [isInitialising, setIsInitialising] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(null)
  const [userData, setUserData] = useState({})

  useEffect(() => {
    setResellerLogo(null)
    if (resellerId > 0) {
      setIsLoadingReseller(true)
      axios.get(`${restUrls.loadResellerWhitelabel}?resellerId=${resellerId}`)
        .then(response => {
          setIsLoadingReseller(false)
          if (response.status === 200) {
            setResellerLogo(response.data.logo)
            setSupportEmail(response.data.supportEmail)
            changeWhitelabelTheme(response.data.colors)
          }
        })
        .catch((error) => {
          setIsLoadingReseller(false)
        });
    }
  }, [resellerId])

  useEffect(() => {
    if (userIsLoggedIn === false) {
      setIsLoading(false)

      return;
    }

    if (userData.hasOwnProperty('id') && userData.id) {
      return
    }

    setIsInitialising(true)
    setIsLoading(true)

    axios.get(restUrls.userInfo)
      .then(response => {
        if (response.status === 401){
          setUserIsLoggedIn(false)
        } else {
          setUserIsLoggedIn(true)
          setUserData(response.data)
        }
        setIsLoading(false)
        setIsInitialising(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setIsInitialising(false)
        }
        setUserIsLoggedIn(false)
        setIsLoading(false)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLoggedIn]);

  useEffect(() => {
    if (userData && ssoTokenAudience && userIsLoggedIn) {
      setIsLoading(true)
      let uri = '/sso/redirect?audience='+ssoTokenAudience
      if (redirectTo) {
        uri += '&'+redirectTo
      }
      window.location.href = uri
    }
  }, [ssoTokenAudience, userData, redirectTo, userIsLoggedIn]);

  if (isLoadingReseller) {
    return <MainLoading />
  }

  return (
    <>
      <AuthContext.Provider value={{userIsLoggedIn, setUserIsLoggedIn, userData, setUserData}}>
        <LoadingContext.Provider value={{isLoading, setIsLoading}}>
          {isLoading && <Loading/>}
          {!isInitialising && (userIsLoggedIn ? <AppPicker logo={resellerLogo}/> :
            <Login logo={resellerLogo} supportEmail={supportEmail}/>)}
        </LoadingContext.Provider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
