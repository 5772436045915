import React, {useState} from 'react'
import './Login.css'
import {UpsellguruLogo} from '@upsellguru/usg-components-lib'
import PasswordRecoveryForm from './containers/PasswordRecoveryForm'
import LoginForm from './containers/LoginForm'
import {getRootStyle} from "../../utils/getRootStyle";

export default function Login({logo, supportEmail}) {
  const [recoverPasswordForm, setRecoverPasswordForm] = useState(false)
  
  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div className="login-form">
          <div className="logo-section">
            {logo ?
              <span dangerouslySetInnerHTML={{__html: logo}} /> :
              <div className="usg-logo">
                <UpsellguruLogo 
                  height="100px"
                  squareTextColor="white"
                  logoColor={getRootStyle('--primaryColor')} 
                /> 
              </div>
            }
          </div>
          {recoverPasswordForm ? <PasswordRecoveryForm/> : <LoginForm supportEmail={supportEmail}/>}
        </div>
      <div>
      {recoverPasswordForm ?
        <p onClick={() => setRecoverPasswordForm(false) }
          className="password-recovery">
          Back to login
        </p>
      :
        <p onClick={() => setRecoverPasswordForm(true) }
          className="password-recovery">
          Forgot your password?
        </p>
      }
        </div>
      </div>
    </div>
  )
}

