import React, {useState, useContext} from 'react'
import {InputText, EmailIcon, Button, LockIcon} from '@upsellguru/usg-components-lib'
import TwoFactorAuthenticationChallengeForm from './TwoFactorAuthenticationChallengeForm'
import {restUrls} from "../../../utils/constants";
import {AuthContext} from "../../../contexts/AuthContext";
import {LoadingContext} from "../../../contexts/LoadingContext";
import axios from "axios";
import {defaultSupportEmail} from "../../../utils/constants";

export default function LoginForm({supportEmail}) {
  const { setUserIsLoggedIn } = useContext(AuthContext)
  const { setIsLoading } = useContext(LoadingContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userLoginData, setUserLoginData] = useState({email: '', password: ''})
  const [errorMessage, setErrorMessage] = useState({})
  const [serverErrorCode, setServerErrorCode] = useState('')
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setUserLoginData({...userLoginData, [name]: value})
  }

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.emailLogin = 'Email Address or Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  }

  const handleLoginSubmit = () => {
    setErrorMessage(validate(userLoginData))
    if (!isSubmitting && Object.keys(errorMessage).length === 0) {
      setIsSubmitting(true)
      setIsLoading(true)
      const userFormData = new FormData();
      userFormData.append('email', userLoginData.email);
      userFormData.append('password', userLoginData.password);
      axios({
        method: 'post',
        url: restUrls.authUrl,
        data: userFormData
      })
        .then(response => {
          if (response.status === 200) {
            setUserIsLoggedIn(true)
            setIsLoading(false)
          }
        })
        .catch((error) => {
          setServerErrorCode(error.response.data.error)
          let message = error.response.data.message
          if (error.response.data.error === 'UserAuthenticationFailure') {
            message = 'There was an error while authenticating. Please check email address and/or password.';
          }
          if (
            error.response.data.error === 'UserTemporarilyLocked' ||
            error.response.data.error === 'UserPermanentlyLocked'
          ) {
            message = error.response.data.errorMessage;
            if (supportEmail) {
              message = message.replace(defaultSupportEmail, supportEmail);
            }
          }
          if (
            error.response.data.error === 'NoAccessibleHotels'
          ) {
            message = error.response.data.errorMessage;
          }
          setServerErrorMessage(message)
          setIsLoading(false)
        })
        .then(() => setIsSubmitting(false));
    }

    return false;
  }

  if (serverErrorCode === 'AuthenticationRequires2FA') {
    return <TwoFactorAuthenticationChallengeForm />;
  }

  return (
    <>
      <div className="input-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleLoginSubmit() : null))}
          labelIcon={<EmailIcon width="13px" color={getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')} />}
          label="Email Address or Username"
          id="email"
          name="email"
          placeholder="Email Address or Username"
          value={userLoginData.email}
          onChange={e => handleLoginInputChange(e)}
        />
        <p className="error">{errorMessage.emailLogin}</p>
      </div>
      <div className="input-wrapper password-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleLoginSubmit() : null))}
          labelIcon={<LockIcon width="16px" color={getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}/>}
          label="Password"
          password={true}
          id="password"
          name="password"
          placeholder="Password"
          value={userLoginData.password}
          onChange={e => handleLoginInputChange(e)}
        />
        <p className="error">{errorMessage.password}</p>
      </div>
      <div>
        <Button text="Login" type="primary" onClick={handleLoginSubmit} />
      </div>
      <div>
        <p className="error">{serverErrorMessage}</p>
      </div>
    </>
  )
}
