import React, {useContext, useState} from 'react'
import axios from 'axios'
import {InputText, Button} from '@upsellguru/usg-components-lib'
import {restUrls} from "../../../utils/constants";
import {AuthContext} from "../../../contexts/AuthContext";

export default function TwoFactorAuthenticationChallengeForm() {
  const { setUserIsLoggedIn } = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [challengeCode, setChallengedCode] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const handleSubmitForm = () => {
    if (!isSubmitting && challengeCode !== '') {
      setIsSubmitting(true)
      const formData = new FormData();
      formData.append('code', challengeCode);
      axios({
        method: 'post',
        url: restUrls.verifyTwoFaChallengeUrl,
        data: formData
      })
        .then(response => {
          setUserIsLoggedIn(true)
        })
        .catch((error) => {
          setServerErrorMessage(error.response.data.message)
        })
        .then(() => setIsSubmitting(false));
    }

    return false;
  }

  return (
    <>
      <p className="two-fa-description error">
        <b>We have detected a different IP address from your last login.</b>
        <br />
        <br />
        To protect your account, we have blocked the login
        attempt. If this was an authorized login, please provide the temporary token we have sent an email to your
        account email address.
      </p>
      <div className="input-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleSubmitForm() : null))}
          label="Please enter the code received by email"
          id="code"
          name="code"
          placeholder=""
          value={challengeCode}
          onChange={e => {setChallengedCode(e.target.value)}}
        />
      </div>
      <div>
        <Button text="Submit" type="primary" onClick={handleSubmitForm} />
      </div>
      <div>
        <p className="error">{serverErrorMessage}</p>
      </div>
    </>
  )
}
