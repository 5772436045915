import React from 'react'
import './MainLoading.css'

export default function MainLoading() {
  return (
    <div className="main-loading">
      <div className="spinner-section"></div>
    </div>
  )
}
