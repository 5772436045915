export const restUrls = {
  loadResellerWhitelabel: '/reseller/white_label',
  authUrl: '/login/authenticate',
  verifyTwoFaChallengeUrl: '/login/verify_challenge',
  passwordRecovery: '/login/password_recovery',
  userInfo: '/me/whoami',
  changePassword: '/me/change_password',
};

export const defaultSupportEmail = 'support@upsellguru.com';
