import React, {useState} from 'react'
import {InputText, EmailIcon, Button} from '@upsellguru/usg-components-lib'
import axios from 'axios'
import {getRootStyle} from "../../../utils/getRootStyle";
import {restUrls} from "../../../utils/constants";

export default function PasswordRecoveryForm() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleRecoverInputChange = (e) => {
    setEmailAddress(e.target.value)
  }

  const handleRecoverSubmit = () => {
    let validationErrorMessage = validate(emailAddress);
    setErrorMessage(validationErrorMessage)

    if (!isSubmitting && validationErrorMessage === '') {
      setIsSubmitting(true)
      const formData = new FormData();
      formData.append('emailAddress', emailAddress);
      axios({
        method: 'post',
        url: restUrls.passwordRecovery,
        data: formData
      })
        .then(response => {
          setErrorMessage('We have sent to your email address your temporary password. Please check your email.');
        })
        .catch((error) => {
          setErrorMessage(
            'There was a problem while resetting your password. Please try again later or contact support team'
          );
        })
        .then(() => setIsSubmitting(false));
    }

    return false;
  }

  const validate = (emailAddress) => {
    if (!emailAddress) {
      return 'Please enter a valid email address or username';
    }

    return '';
  }

  return (
    <>
      <div className="input-wrapper">
        <InputText
          label="Email address or Username"
          labelIcon={<EmailIcon width="13px" color={getRootStyle('--primaryColor')} />}
          placeholder='Email address or Username'
          value={emailAddress}
          onChange={e => handleRecoverInputChange(e)} 
        />
        <p className="error">{errorMessage}</p>
      </div>
      <div>
        <Button text="Recover password" type="primary" onClick={handleRecoverSubmit} />
      </div>
    </>
  )
}
